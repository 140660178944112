import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Container from '~components/Container';
import HeroImage from '~images/black-friday/black-friday-welcome-hero.svg';
import RegisterButton from '~containers/landing/RegisterButton';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#000000'
  },
  heroContainer: {
    width: '100%'
  },
  contentContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row !important',
    alignItems: 'center !important',
    paddingBottom: '40px !important',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column !important',
      alignItems: 'center !important'
    }
  },
  heroContentArea: {
    position: 'relative',
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      order: 2
    }
  },
  heroImage: {
    paddingTop: '80px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '450px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingTop: '40px',
      maxWidth: '350px',
      order: 1
    }
  },
  heroContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '470px',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  heroTitle: {
    fontFamily: 'Inter',
    fontSize: '45px',
    fontWeight: 600,
    color: '#FFFFFF',
    marginTop: 0,
    marginBottom: 0,
    [theme.breakpoints.down('xs')]: {
      fontSize: '35px'
    }
  },
  strikeOuter: {
    color: 'red',
    textDecoration: 'line-through'
  },
  strikeInner: {
    color: '#FFFFFF'
  },
  heroLabel: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 500,
    color: '#FFFFFF',
    textTransform: 'uppercase',
    letterSpacing: '1.6px',
    lineHeight: '106%',
    marginBottom: '36px'
  },
  heroDescription: {
    fontFamily: 'Roboto',
    fontSize: '32px',
    fontWeight: 500,
    color: '#FF5C5C',
    textTransform: 'capitalize',
    marginTop: 0,
    marginBottom: 0,
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px'
    }
  },
  heroSubDescription: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 500,
    color: '#FFFFFF',
    marginTop: 40,
    marginBottom: 0
  },
  buttonArea: {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    paddingTop: '20px',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  }
}));

const BlackFridayWelcomeHero = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Box component="div" className={classes.heroContainer}>
        <Container className={classes.contentContainer}>
          <div className={classes.heroContentArea}>
            <div className={classes.heroContent}>
              <p className={classes.heroLabel}>Limited Time Offer</p>
              <h1 className={classes.heroTitle}>
                <span className={classes.strikeOuter}>
                  <span className={classes.strikeInner}>$18</span>
                </span>{' '}
                $3 for 3 months
              </h1>
              <h2 className={classes.heroDescription}>the biggest deal of the year</h2>
              <p className={classes.heroSubDescription}>Get an Aleph Beta Premium Subscription</p>
              <div className={classes.buttonArea}>
                <RegisterButton
                  size="small"
                  variant="white"
                  analyticsTitle="Black Friday Hero"
                  analyticsSection="Hero"
                />
              </div>
            </div>
          </div>
          <img src={HeroImage} alt="Black Friday 2023 Offer" className={classes.heroImage} />
        </Container>
      </Box>
    </div>
  );
};

BlackFridayWelcomeHero.propTypes = {};

BlackFridayWelcomeHero.defaultProps = {};

export default BlackFridayWelcomeHero;
