import React from 'react';
import { FEATURE_FLAGS, isFeatureEnabled } from '~utils/feature-helpers';
import useBlackFridayTargetAudience from '~components/black-friday/useTargetAudience';
import BlackFridayWelcomeHero from '~components/black-friday/BlackFridayWelcomeHero';
import AnimationHero from './AnimationHero';

const IS_BLACK_FRIDAY_OFFER_ENABLED = isFeatureEnabled(FEATURE_FLAGS.BLACK_FRIDAY_OFFER);

const AnimationHeroWithBlackFriday = props => {
  const isBlackFridayTargetAudience = useBlackFridayTargetAudience();

  if (IS_BLACK_FRIDAY_OFFER_ENABLED && isBlackFridayTargetAudience) {
    return <BlackFridayWelcomeHero />;
  }
  return <AnimationHero {...props} />;
};
export default AnimationHeroWithBlackFriday;
